import React from "react"
import ReactDOMServer from "react-dom/server"

import marked from "marked"

import styles from "./timelineGroup.module.scss"

const renderer = {
  paragraph(text) {
    return ReactDOMServer.renderToString(
      <p className={styles.marginBottomMD} dangerouslySetInnerHTML={{ __html: text }}/>
    )
  }
}

marked.use({ renderer })

const TimelineGroup = ({ components }) =>
  components.map((component, i) => {
    const { company, description, position, workPeriod } = component

    return (
			<div key={`${i}-timelineGroupContent-${position}`}>
				<h4 className={styles.marginBottomSM}>
					{company}, <em>{position}</em>. <span className={styles.light}>{workPeriod}.</span>
				</h4>
				<div dangerouslySetInnerHTML={{ __html: marked(description) }} />
			</div>
		);
  })

export default TimelineGroup
