import React from 'react';

import styles from './shortTimeline.module.scss';

const ShortTimeline = ({ component }) => {
	const { title, components } = component;

	return (
		<>
			<h4 className={styles.title}>{title}</h4>
			<div className={styles.split}>
				{components.map((timelineItem, i) => {
					const timelineListItems = timelineItem.title.split(',');

					return (
						<p key={`${i}-${timelineListItems[0]}`} className={styles.timelineItem}>
							<span className={styles.year}>{timelineItem.years}</span>
							<span>
								{timelineListItems.map((text, i) => (
									<span key={`${i}-${text}-timelineItem`} className={styles.timelineListItem}>
										{text}
									</span>
								))}
							</span>
						</p>
					);
				})}
			</div>
		</>
	);
};

export default ShortTimeline;
