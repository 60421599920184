import React from 'react';
import ReactDOMServer from 'react-dom/server';

import marked from 'marked';

import styles from './fullTimeline.module.scss';

const renderer = {
	paragraph(text) {
		const textParts = text.split(/\r?\n/);

		return ReactDOMServer.renderToString(
			<>
				<p className={styles.marginBottomMD}>
					{textParts.map((text, i) => (
						<span key={`${text}-${i}`} className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
					))}
				</p>
			</>,
		);
	},
};

marked.use({ renderer });

const FullTimeline = ({ component }) => {
	const { components, title } = component;

	return (
		<>
			<h4 className={styles.title}>{title}</h4>

			{components.map((component, i) => {
				const { years, description } = component;

				return (
					<div key={`${i}-${description.slice(4)}-fullTime`} className={styles.timelineItem}>
						<span className={styles.year}>{years}</span>

						<div className={styles.timelineItemDiv} dangerouslySetInnerHTML={{ __html: marked(description) }} />
					</div>
				);
			})}
		</>
	);
};

export default FullTimeline;
