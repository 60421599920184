import React from 'react';

import styles from './footer.module.scss';

const Footer = ({ component }) => {
	const { address1, address2, email, phoneNumber, phoneNumber2 } = component;

	return (
		<footer className={styles.footer}>
			{phoneNumber && (
				<div className={styles.footerCol}>
					<p className={styles.phone}>
						<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
					</p>
				</div>
			)}
			{address1 && (
				<div className={styles.footerCol}>
					<p className={styles.address}>{address1}</p>
				</div>
			)}
			<div className={styles.footerCol}>
				<p>
					<a href={`mailto:${email}`}>{email}</a>
				</p>
			</div>
			{address2 && (
				<div className={styles.footerCol}>
					<p className={styles.address2}>{address2}</p>
				</div>
			)}
			{phoneNumber2 && (
				<div className={styles.footerCol}>
					<p className={styles.phone}>
						<a href={`tel:${phoneNumber2}`}>{phoneNumber2}</a>
					</p>
				</div>
			)}
		</footer>
	);
};

export default Footer;
