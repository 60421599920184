import React from 'react';

import TimelineGroup from './TimelineGroup';
import ShortTimeline from './ShortTimeline';
import FullTimeline from './FullTimeline';

import styles from './homePageBlock.module.scss';

const HomePageBlock = ({ component, className }) => {
	const { blockPosition, components } = component;

	return (
		<section className={`${styles.block} ${styles[blockPosition === 'left' ? 'left' : 'right']} ${className}`}>
			{components.map((c, i) => {
				const { workInfo, shortTimeline, fullTimeline, title } = c;

				if (!!workInfo) {
					return <TimelineGroup key={`${i}-timelineGroup`} components={workInfo} />;
				}

				if (!!shortTimeline) {
					return <ShortTimeline key={`${i}-shortTimeline`} component={{ components: shortTimeline, title }} />;
				}

				if (!!fullTimeline) {
					return <FullTimeline key={`${i}-fullTimeline`} component={{ components: fullTimeline, title }} />;
				}

				return null;
			})}
		</section>
	);
};

export default HomePageBlock;
