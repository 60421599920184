import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import Footer from "../components/Footer"
import styles from "./homePage.module.scss"

import HomePageBlock from "../containers/HomePageBlock"

export default function Home() {
  const {
    homePage: { nodes }
  } = useStaticQuery(graphql`
		query GetHomePage {
			homePage: allStrapiHomePage {
				nodes {
					title: Title
					blocks {
						componentName: __typename
						blockPosition: BlockPosition
						components {
							componentName: __typename
							workInfo {
								componentName: __typename
								company: Company
								id
								position: Position
								workPeriod: WorkPeriod
								description: Description
							}
							title: Title
							shortTimeline: ShortTimeline {
								componentName: __typename
								years: Years
								title: Title
								id
							}
							fullTimeline: FullTimeline {
								componentName: __typename
								id
								years: Years
								description: Description
							}
						}
					}
					footer: Footer {
						componentName: __typename
						phoneNumber: PhoneNumber
						id
						address1: Address1
						email: Email
						address2: Address2
						phoneNumber2: PhoneNumber2
					}
				}
			}
		}
	`)

  const { footer, title, blocks } = nodes[0]

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.row}>
        {blocks.map((block, i) => (
          <HomePageBlock key={`block-${i}`} component={block} className={styles.colFirst}/>
        ))}
      </div>
      <Footer component={footer}/>
    </div>
  )
}
